import axiosInstance from "@/api/axios-instance";

export async function getAllProfessionals() {
  try {
    const response = await axiosInstance.get("/professional", {
      params: {
        page: 0,
        limit: 1000,
      },
    });

    if (!response.status.toString().startsWith("2")) {
      throw new Error("Could not load professionals");
    }

    return {
      professionals: response.data.professionals || [],
    };
  } catch (error) {
    console.error("Error fetching all professionals:", error);
    // to prevent ui breaking
    return {
      professionals: [],
    };
  }
}
