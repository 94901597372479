import axiosInstance from "@/api/axios-instance";

export async function getProfessionals({
  page,
  search,
  status,
  job_commitment,
  qualification,
}: {
  page: number;
  search?: string;
  status?: string;
  job_commitment?: string;
  qualification?: string[];
}) {
  // Create params object
  const params: Record<string, any> = { page };

  // Add filter parameters if they exist
  if (search) params.search = search;
  if (status) params.status = status;
  if (job_commitment) params.job_commitment = job_commitment;
  if (qualification && qualification.length > 0) {
    params.qualification = qualification;
  }

  // Make the API request
  const response = await axiosInstance.get("/professional", { params });

  if (!response.status.toString().startsWith("2")) {
    throw new Error("professionals are not loaded");
  }

  const {
    professionals,
    nextPage,
    lastPage,
    page: currentPage,
    total,
  } = response.data;

  return {
    professionals,
    nextPage,
    lastPage,
    currentPage,
    total,
  };
}
