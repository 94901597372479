// services/get-products.ts
import axiosInstance from "@/api/axios-instance";
import { Product } from "@/pages/forms/types/product";

export async function getProducts(): Promise<Product[]> {
  const response = await axiosInstance.get<Product[]>("/product");

  if (!response.status.toString().startsWith("2")) {
    throw new Error("products are not loaded");
  }

  return response.data;
}
