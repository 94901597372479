import axiosInstance from "@/api/axios-instance";
import { Form } from "@/pages/forms/types/form";

export async function createForm(
  data: Omit<Form, "id" | "created_at" | "updated_at">
) {
  const response = await axiosInstance.post<Form>("/forms", data);

  if (!response.status.toString().startsWith("2")) {
    throw new Error("Failed to create form");
  }

  return response.data;
}
