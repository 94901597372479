import axiosInstance from "@/api/axios-instance";
import { Form } from "@/pages/forms/types/form";

export async function getForms({
  page,
  search,
  status,
  department,
}: {
  page: number;
  search?: string;
  status?: string;
  department?: string;
}) {
  const params: Record<string, any> = { page };

  if (search) params.search = search;

  if (status && status !== "ALL") {
    params.status = status;
  }

  // matching the format
  if (department && department !== "ALL") {
    params.department = department;
  }

  // api request
  const response = await axiosInstance.get("/forms", { params });

  if (!response.status.toString().startsWith("2")) {
    throw new Error("forms are not loaded");
  }

  const { forms, nextPage, lastPage, page: currentPage, total } = response.data;

  return {
    forms,
    nextPage,
    lastPage,
    currentPage,
    total,
  };
}
