import { useQuery } from "@tanstack/react-query";
import { getActiveBundles } from "../services/get-active-bundles";

export function useActiveBundles() {
  return useQuery({
    queryKey: ["active-bundles"],
    queryFn: getActiveBundles,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 30,
  });
}