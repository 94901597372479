import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useForm } from "react-hook-form";
import { Search, X } from "lucide-react";
import MultiSelectTags from "../ui/multi-select-tags";
import {
  professionalStatusLabels,
  jobCommitmentLabels,
} from "../schema/professional-schema";

// Define the filter form interface
interface ProfessionalFilters {
  search: string;
  status: string;
  job_commitment: string;
  qualification: string[];
}

interface ProfessionalFilterProps {
  onApplyFilters: (filters: ProfessionalFilters) => void;
}

export default function ProfessionalFilter({
  onApplyFilters,
}: ProfessionalFilterProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  // Initialize filters from URL params
  const initialFilters: ProfessionalFilters = {
    search: searchParams.get("search") || "",
    status: searchParams.get("status") || "ALL",
    job_commitment: searchParams.get("job_commitment") || "ALL",
    qualification: searchParams.get("qualification")?.split(",") || [],
  };

  // Initialize form with values from URL
  const form = useForm<ProfessionalFilters>({
    defaultValues: initialFilters,
  });

  // Update URL when filters change
  const applyFilters = (values: ProfessionalFilters) => {
    const params = new URLSearchParams();
    params.set("page", "0"); // Reset to first page when filters change

    if (values.search) params.set("search", values.search);
    if (values.status !== "ALL") params.set("status", values.status);
    if (values.job_commitment !== "ALL")
      params.set("job_commitment", values.job_commitment);
    if (values.qualification.length > 0)
      params.set("qualification", values.qualification.join(","));

    setSearchParams(params);
    onApplyFilters(values);
  };

  // Handle form submission
  const onSubmit = (values: ProfessionalFilters) => {
    applyFilters(values);
  };

  // Reset all filters
  const handleReset = () => {
    form.reset({
      search: "",
      status: "ALL",
      job_commitment: "ALL",
      qualification: [],
    });
    setSearchParams(new URLSearchParams());
    onApplyFilters({
      search: "",
      status: "ALL",
      job_commitment: "ALL",
      qualification: [],
    });
  };

  // Update form when URL changes
  useEffect(() => {
    form.reset({
      search: searchParams.get("search") || "",
      status: searchParams.get("status") || "ALL",
      job_commitment: searchParams.get("job_commitment") || "ALL",
      qualification: searchParams.get("qualification")?.split(",") || [],
    });
  }, [searchParams, form]);

  // Check if any filters are applied
  const areFiltersApplied =
    !!searchParams.get("search") ||
    (searchParams.get("status") !== "ALL" && !!searchParams.get("status")) ||
    (searchParams.get("job_commitment") !== "ALL" &&
      !!searchParams.get("job_commitment")) ||
    !!searchParams.get("qualification");

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        {/* First row - Search and status */}
        <div className="flex flex-col md:flex-row gap-4">
          <FormField
            control={form.control}
            name="search"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormControl>
                  <div className="relative">
                    <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
                    <Input
                      placeholder="Search by name, email, or phone..."
                      className="pl-8"
                      {...field}
                    />
                  </div>
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="status"
            render={({ field }) => (
              <FormItem>
                <Select value={field.value} onValueChange={field.onChange}>
                  <FormControl>
                    <SelectTrigger className="w-[180px]">
                      <SelectValue placeholder="Status" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="ALL">All Statuses</SelectItem>
                    {Object.entries(professionalStatusLabels).map(
                      ([value, label]) => (
                        <SelectItem key={value} value={value}>
                          {label}
                        </SelectItem>
                      )
                    )}
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="job_commitment"
            render={({ field }) => (
              <FormItem>
                <Select value={field.value} onValueChange={field.onChange}>
                  <FormControl>
                    <SelectTrigger className="w-[180px]">
                      <SelectValue placeholder="Job Commitment" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="ALL">All Commitments</SelectItem>
                    {Object.entries(jobCommitmentLabels).map(
                      ([value, label]) => (
                        <SelectItem key={value} value={value}>
                          {label}
                        </SelectItem>
                      )
                    )}
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />

          <div className="flex gap-2">
            <Button type="submit" className="bg-sidebar">
              Apply Filters
            </Button>

            {areFiltersApplied && (
              <Button
                type="button"
                variant="outline"
                onClick={handleReset}
                className="flex items-center gap-1"
              >
                <X className="h-4 w-4" />
                Reset
              </Button>
            )}
          </div>
        </div>

        {/* Second row - Qualifications */}
        <div className="flex flex-col md:flex-row gap-4">
          <FormField
            control={form.control}
            name="qualification"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>Qualifications</FormLabel>
                <FormControl>
                  <MultiSelectTags
                    value={field.value}
                    onChange={field.onChange}
                    placeholder="Enter qualification and press Enter"
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>
      </form>
    </Form>
  );
}
