import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Ticket } from "lucide-react";
import { Card, CardContent } from "@/components/ui/card";
import ThreeDotsLoader from "@/components/common/three-dots-loader";
import CustomPaginationComponent from "@/components/common/custom-pagination-component";
import useCoupons from "@/features/coupons/hooks/use-coupons";
import { CouponCard } from "@/features/coupons/ui/coupon-card";
import VaulDrawer from "@/components/ui/drawer";
import AddEditCouponForm from "@/features/coupons/ui/coupon-form";
import { CouponData } from "@/features/coupons/schema/coupon-schema";
import CouponFilter from "@/features/coupons/ui/coupon-filter";

// Define the filter interface
interface CouponFilters {
  search: string;
  status: string;
}

export default function Coupons() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showCouponDrawer, setShowCouponDrawer] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState<CouponData>();
  const navigate = useNavigate();

  // Get filter values from URL params
  const page = parseInt(searchParams.get("page") || "0", 10);
  const search = searchParams.get("search") || "";
  const status = searchParams.get("status") || undefined;

  // Fetch coupons with filters
  const { data, error, isFetching, isLoading, isSuccess } = useCoupons(
    page,
    search,
    status
  );

  if (error) {
    console.error("Error in Coupons component:", error);
    return <></>;
  }

  if (isFetching || isLoading || !data) {
    return <ThreeDotsLoader />;
  }

  const { coupons, lastPage: lastSkip, currentPage } = data;
  const lastPage = lastSkip + 1;

  const handlePageChange = (page: number) => {
    if (page >= 0 && page < lastPage) {
      // Keep the existing search params and just update the page
      const newParams = new URLSearchParams(searchParams);
      newParams.set("page", page.toString());

      const newUrl = `?${newParams.toString()}`;
      navigate(newUrl, { replace: true });
    }
  };

  const handleApplyFilters = (filters: CouponFilters) => {
    // Build new URL params
    const newParams = new URLSearchParams();
    newParams.set("page", "0");

    if (filters.search) {
      newParams.set("search", filters.search);
    }

    if (filters.status && filters.status !== "ALL") {
      newParams.set("status", filters.status);
    }

    const newUrl = `?${newParams.toString()}`;
    navigate(newUrl, { replace: true });
  };

  return (
    <div className="flex-1 overflow-auto">
      <div className="h-full p-8">
        <div className="space-y-6">
          {/* Add the filter component */}
          <CouponFilter onApplyFilters={handleApplyFilters} />

          {isSuccess && coupons?.length > 0 ? (
            <>
              <CouponCard
                coupons={coupons}
                setSelectedCoupon={setSelectedCoupon}
                setShowCouponDrawer={setShowCouponDrawer}
              />
              <CustomPaginationComponent
                currentPage={currentPage}
                lastPage={lastPage}
                onPageChange={handlePageChange}
              />
            </>
          ) : (
            <Card>
              <CardContent className="flex flex-col items-center justify-center py-8">
                <Ticket className="h-8 w-8 text-muted-foreground" />
                <p className="mt-2 text-center text-sm text-muted-foreground">
                  No coupons found matching your criteria
                </p>
              </CardContent>
            </Card>
          )}
        </div>
      </div>

      <VaulDrawer
        drawerDirection="right"
        isOpen={showCouponDrawer}
        onOpenChange={setShowCouponDrawer}
        triggerChildren={null}
        contentChildren={
          <AddEditCouponForm
            editMode={selectedCoupon ? true : false}
            onClose={() => setShowCouponDrawer(false)}
            initialData={selectedCoupon}
            setShowCouponDrawer={setShowCouponDrawer}
          />
        }
      />
    </div>
  );
}
