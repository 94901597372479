import { useQuery } from "@tanstack/react-query";
import { getProfessionals } from "../services/get-professionals";
import { Professional } from "@/pages/professionals/types/professional";

interface PaginatedProfessionals {
  professionals: Array<Professional>;
  lastPage: number;
  nextPage: number | null;
  currentPage: number;
  total: number;
}

export default function useProfessionals(
  page: number = 0,
  search?: string,
  status?: string,
  job_commitment?: string,
  qualification?: string[]
) {
  const { data, isLoading, error, isFetching, isSuccess } =
    useQuery<PaginatedProfessionals>({
      queryKey: [
        "professionals",
        page,
        search,
        status,
        job_commitment,
        qualification,
      ],
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 30, // 30 minutes
      queryFn: () =>
        getProfessionals({
          page,
          search,
          status,
          job_commitment,
          qualification,
        }),
    });

  return { data, isLoading, error, isFetching, isSuccess };
}
