import { useSearchParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import { SessionCard } from "@/features/sessions/ui/session-card";
import useSessions from "@/features/sessions/hooks/use-sessions";
import ThreeDotsLoader from "@/components/common/three-dots-loader";
import { SearchFilters } from "./types/session";
import { SessionSearch } from "@/features/sessions/ui/session-search";
import CustomPaginationComponent from "@/components/common/custom-pagination-component";

export default function Sessions() {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = parseInt(searchParams.get("page") || "0", 10);
  const usage_id = searchParams.get("usage_id") ?? undefined;
  const debouncedSearchRef = useRef<NodeJS.Timeout>();

  // initialize filters from URL params
  const initialFilters: SearchFilters = {
    search: searchParams.get("search") || "",
    dateRange: {
      start: null,
      end: null,
    },
    status: "all",
    professionalId: searchParams.get("professional_id") || "ALL",
    productCategory: searchParams.get("product_id") || "ALL",
  };

  // parse filters from URL if they exist
  const urlFilters = searchParams.get("filter")?.split(",");
  if (urlFilters) {
    urlFilters.forEach((filter) => {
      const [key, value] = filter.split("::");
      if (key === "start_date") {
        initialFilters.dateRange.start = new Date(value);
      } else if (key === "end_date") {
        initialFilters.dateRange.end = new Date(value);
      } else if (key === "status") {
        initialFilters.status = value;
      }
    });
  }

  // helper function to build filter string
  const buildFilterString = (filters: SearchFilters): string => {
    const filterParts: string[] = [];

    if (filters.dateRange.start) {
      filterParts.push(`start_date::${filters.dateRange.start.toISOString()}`);
    }
    if (filters.dateRange.end) {
      filterParts.push(`end_date::${filters.dateRange.end.toISOString()}`);
    }
    if (filters.status !== "all") {
      filterParts.push(`status::${filters.status}`);
    }

    return filterParts.join(",");
  };

  const handleSearch = (filters: SearchFilters) => {
    // clearing any existing timeout
    if (debouncedSearchRef.current) {
      clearTimeout(debouncedSearchRef.current);
    }

    // set a new timeout for updating search params
    debouncedSearchRef.current = setTimeout(() => {
      const filterString = buildFilterString(filters);
      const newParams = new URLSearchParams();

      newParams.set("page", "0");
      if (filters.search) newParams.set("search", filters.search);
      if (filterString) newParams.set("filter", filterString);
      if (usage_id) newParams.set("usage_id", usage_id);

      // Add new filter params - using snake_case for URL parameters
      if (filters.professionalId && filters.professionalId !== "ALL")
        newParams.set("professional_id", filters.professionalId);
      if (filters.productCategory && filters.productCategory !== "ALL")
        newParams.set("product_id", filters.productCategory);

      setSearchParams(newParams);
    }, 1000); // 1000ms debounce delay
  };

  // useffect cleanup on unmount
  useEffect(() => {
    return () => {
      if (debouncedSearchRef.current) {
        clearTimeout(debouncedSearchRef.current);
      }
    };
  }, []);

  const handlePageChange = (newPage: number) => {
    if (newPage >= 0 && newPage < lastPage) {
      const newParams = new URLSearchParams(searchParams);
      newParams.set("page", newPage.toString());
      if (usage_id) newParams.set("usage_id", usage_id);
      setSearchParams(newParams);
    }
  };

  // Get IDs from URL params using snake_case keys
  const product_id = searchParams.get("product_id") !== "ALL" ? 
    searchParams.get("product_id") || undefined : undefined;
  const professional_id = searchParams.get("professional_id") !== "ALL" ? 
    searchParams.get("professional_id") || undefined : undefined;

  const { data, isSuccess, error, isFetching, isLoading } = useSessions(
    page,
    searchParams.get("search") || "",
    searchParams.get("filter") || "",
    usage_id,
    professional_id,
    product_id
  );

  if (error) {
    return <></>;
  }

  if (isFetching || isLoading || !data) {
    return <ThreeDotsLoader />;
  }

  const { lastPage: lastSkip, currentPage, sessions, nextPage } = data;
  const lastPage = lastSkip + 1;

  return (
    <div className="flex-1 overflow-auto">
      <div className="h-full p-8 md:w-[65vw] lg:w-[68vw] xl:w-[75vw] 2xl:w-[80vw]">
        <SessionSearch
          onSearch={handleSearch}
          initialFilters={initialFilters}
        />
        <div className="space-y-6 mt-6">
          {isSuccess && sessions.length > 0 ? (
            sessions.map((session) => (
              <SessionCard key={session.id} session={session} />
            ))
          ) : (
            <p className="text-center text-gray-500">No sessions found.</p>
          )}

          {sessions?.length ? (
            <CustomPaginationComponent
              currentPage={currentPage}
              lastPage={lastPage}
              onPageChange={handlePageChange}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}