import axiosInstance from "@/api/axios-instance";

export async function getBundles({
  page,
  status,
  product_id,
}: {
  page: number;
  status?: string;
  product_id?: string;
}) {
  const params: Record<string, any> = { page };

  if (status && status !== "ALL") {
    params.status = status;
  }

  if (product_id && product_id !== "ALL") {
    params.product_id = product_id;
  }

  const response = await axiosInstance.get("/bundle", {
    params,
  });

  if (!response.status.toString().startsWith("2")) {
    throw new Error("bundles are not loaded");
  }

  const { bundles, nextPage, lastPage, page: currentPage, total } = response.data;
  return {
    bundles,
    nextPage,
    lastPage,
    currentPage,
    total,
  };
}