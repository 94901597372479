import { useQuery } from "@tanstack/react-query";
import { getForms } from "../services/get-forms";
import { Form } from "@/pages/forms/types/form";

interface PaginatedForms {
  forms: Form[];
  lastPage: number;
  nextPage: number | null;
  currentPage: number;
  total: number;
}

export default function useForms(
  page: number = 0,
  search?: string,
  status?: string,
  department?: string
) {
  const { data, isLoading, error, isFetching, isSuccess } =
    useQuery<PaginatedForms>({
      queryKey: [
        "forms",
        {
          page,
          search,
          status,
          department,
        },
      ],
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 30, // 30 minutes
      queryFn: () => getForms({ page, search, status, department }),
    });

  return { data, isLoading, error, isFetching, isSuccess };
}
