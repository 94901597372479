import React, {
  useState,
  KeyboardEvent,
  ChangeEvent,
  useRef,
  useEffect,
} from "react";
import { Input } from "@/components/ui/input";
import { X } from "lucide-react";

interface LanguageTagsInputProps {
  value: string[];
  onChange: (value: string[]) => void;
  className?: string;
}

// language array
const LANGUAGE_OPTIONS = [
  "English",
  "Hindi",
  "Bengali",
  "Kannada",
  "Marathi",
  "Assamese",
  "Gujarati",
  "Tamil",
  "Kutchi",
  "Urdu",
  "Punjabi",
  "Sindhi",
  "Telugu",
  "Malayalam",
];

const LanguageTagsInput: React.FC<LanguageTagsInputProps> = ({
  value = [],
  onChange,
  className = "",
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [filteredOptions, setFilteredOptions] = useState<string[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showDropdown) {
      if (inputValue.trim() === "") {
        setFilteredOptions(
          LANGUAGE_OPTIONS.filter((option) => !value.includes(option))
        );
      } else {
        const filtered = LANGUAGE_OPTIONS.filter(
          (option) =>
            option.toLowerCase().includes(inputValue.toLowerCase()) &&
            !value.includes(option)
        );
        setFilteredOptions(filtered);
      }
    }
  }, [inputValue, value, showDropdown]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();

      const matchingOption = LANGUAGE_OPTIONS.find(
        (option) =>
          option.toLowerCase() === inputValue.toLowerCase() &&
          !value.includes(option)
      );

      if (matchingOption) {
        addTag(matchingOption);
      } else if (filteredOptions.length > 0) {
        addTag(filteredOptions[0]);
      }
    } else if (e.key === "Backspace" && !inputValue && value.length > 0) {
      onChange(value.slice(0, -1));
    } else if (e.key === "Escape") {
      setShowDropdown(false);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    if (!showDropdown) {
      setShowDropdown(true);
    }
  };

  const addTag = (tag: string) => {
    if (tag && !value.includes(tag)) {
      onChange([...value, tag]);
    }
    setInputValue("");
    setShowDropdown(false);
  };

  const removeTag = (tagToRemove: string) => {
    onChange(value.filter((tag) => tag !== tagToRemove));
  };

  const handleFocus = () => {
    setShowDropdown(true);
  };

  return (
    <div className="relative w-full">
      <div
        className={`w-full border rounded-lg p-2 flex items-center flex-wrap gap-2 bg-white focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2 ${className}`}
      >
        {value.map((tag, index) => (
          <span
            key={index}
            className="bg-gray-100 text-gray-800 px-3 py-1 rounded-full text-[13px] flex items-center gap-x-1 group"
          >
            {tag}
            <button
              type="button"
              onClick={() => removeTag(tag)}
              className="hover:bg-gray-200 rounded-full p-0.5 transition-colors"
            >
              <X size={13} />
            </button>
          </span>
        ))}
        <Input
          ref={inputRef}
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          className="flex-1 !border-none shadow-none !ring-0 !outline-none min-w-[120px] h-8 p-0"
          placeholder={
            value.length === 0 ? "Click here to select languages" : ""
          }
        />
      </div>

      {showDropdown && filteredOptions.length > 0 && (
        <div
          ref={dropdownRef}
          className="absolute z-10 mt-1 w-full bg-white border rounded-md shadow-lg max-h-60 overflow-auto"
        >
          {filteredOptions.map((option) => (
            <div
              key={option}
              className="px-4 py-2 cursor-pointer hover:bg-gray-100"
              onClick={() => addTag(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageTagsInput;
