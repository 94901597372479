export function getExceptionScheduleLink({
  standard_id,
  usage_id,
  session_event_type,
}: {
  standard_id: string;
  usage_id: string;
  session_event_type: string;
}) {
  const adult_adhd_event_type =
    "https://api.calendly.com/event_types/4256dd81-1fbd-4f51-b569-5f1350c3aed6";
  const child_adhd_event_type =
    "https://api.calendly.com/event_types/05a20f65-5cd0-4dff-85b3-71faba81ed24";

  return standard_id === "1388d1f3-fff0-4a5c-9fe9-51c82baf09de" ||
    standard_id === "2fc607a3-32eb-4b2c-bf0d-9c3ac3c46e09"
    ? `https://www.rockethealth.app/schedule/cancer-care?usage_id=${usage_id}&exception=true`
    : standard_id === "202844fa-9add-4ea4-9e1b-0db98212ac80" ||
      standard_id === "256e4218-6566-4e43-a33d-46db1de00436"
    ? `https://www.rockethealth.app/schedule/personality-disorder?usage_id=${usage_id}&exception=true`
    : standard_id === "526204b4-8ce6-4a41-b423-87455b4e1bed" ||
      standard_id === "ac61f142-021a-4cde-b380-98cb0aaee743"
    ? `https://www.rockethealth.app/schedule/psychiatry?usage_id=${usage_id}&exception=true`
    : (standard_id === "ecd5a752-7c2c-4d0e-8285-539f41766891" ||
        standard_id === "256e4218-6566-4e43-a33d-46db1de00436") &&
      session_event_type === adult_adhd_event_type
    ? `https://www.rockethealth.app/schedule/adhd?usage_id=${usage_id}&exception=true`
    : (standard_id === "ecd5a752-7c2c-4d0e-8285-539f41766891" ||
        standard_id === "256e4218-6566-4e43-a33d-46db1de00436") &&
      session_event_type === child_adhd_event_type
    ? `https://www.rockethealth.app/schedule/child-adhd?usage_id=${usage_id}&exception=true`
    : ``;
}
