import { useQuery } from "@tanstack/react-query";
import { getProducts } from "../services/get-products";
import { Product } from "@/pages/forms/types/product";

export function useProducts() {
  const { data, isLoading, error, isFetching, isSuccess } = useQuery<Product[]>(
    {
      queryKey: ["products"],
      queryFn: getProducts,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 30, // 30 minutes
    }
  );

  return { data, isLoading, error, isFetching, isSuccess };
}