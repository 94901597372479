import { useQuery } from "@tanstack/react-query";
import { getUser } from "../services/get-user";
import { UserProfile } from "@/pages/users/types/user";

export default function useUser(userId: string) {
  const { data, isLoading, error, isFetching } = useQuery<UserProfile, Error>({
    queryKey: ["user", userId],
    queryFn: () => getUser(userId),
    enabled: !!userId,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 30,
  });

  return { data, isLoading, error, isFetching };
}
