import { useQuery } from "@tanstack/react-query";
import { getUsers } from "../services/get-users";

export default function useUsers({
  page = 0,
  search = "",
  is_lead,
  product_id,
}: {
  page: number;
  search?: string;
  is_lead?: boolean;
  product_id?: string;
}) {
  const { data, isLoading, error, isFetching, isSuccess } = useQuery({
    queryKey: [
      "users",
      {
        page,
        search,
        is_lead,
        product_id,
      },
    ],
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 30, // 30 minutes
    queryFn: () => getUsers({ page, search, is_lead, product_id }),
  });

  return { data, isLoading, error, isFetching, isSuccess };
}