import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { X } from "lucide-react";
import { useForm } from "react-hook-form";

// Define the filter form interface
interface FormFilters {
  status: string;
  department: string;
}

interface FormFilterProps {
  onApplyFilters: (filters: FormFilters) => void;
}

export default function FormFilter({ onApplyFilters }: FormFilterProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  // Define departments exactly as provided by the backend
  const departments = [
    { value: "ALL", label: "All Departments" },
    { value: "Therapy", label: "Therapy" },
    { value: "Psychiatry", label: "Psychiatry" },
    { value: "Women's Health", label: "Women's Health" },
    { value: "Men's Sexual Health", label: "Men's Sexual Health" },
  ];

  // Initialize filters from URL params
  const initialFilters: FormFilters = {
    status: searchParams.get("status") || "ALL",
    department: searchParams.get("department") || "ALL",
  };

  // Initialize form with values from URL
  const form = useForm<FormFilters>({
    defaultValues: initialFilters,
  });

  // Apply filters to URL and trigger callback
  const applyFilters = (values: FormFilters) => {
    const params = new URLSearchParams();

    // Always preserve the page parameter but reset to 0 when filters change
    params.set("page", "0");

    if (values.status && values.status !== "ALL") {
      params.set("status", values.status);
    }

    if (values.department && values.department !== "ALL") {
      params.set("department", values.department);
    }

    setSearchParams(params);
    onApplyFilters(values);
  };

  // Handle direct status changes
  const handleStatusChange = (newStatus: string) => {
    const currentValues = form.getValues();
    applyFilters({
      ...currentValues,
      status: newStatus,
    });
  };

  // Handle direct department changes
  const handleDepartmentChange = (newDepartment: string) => {
    const currentValues = form.getValues();
    applyFilters({
      ...currentValues,
      department: newDepartment,
    });
  };

  // Reset all filters
  const handleReset = () => {
    form.reset({
      status: "ALL",
      department: "ALL",
    });

    // Clear filters but keep on page 0
    setSearchParams(new URLSearchParams({ page: "0" }));

    onApplyFilters({
      status: "ALL",
      department: "ALL",
    });
  };

  // Update form when URL changes
  useEffect(() => {
    form.reset({
      status: searchParams.get("status") || "ALL",
      department: searchParams.get("department") || "ALL",
    });
  }, [searchParams, form]);

  // Check if any filters are applied
  const areFiltersApplied =
    !!searchParams.get("status") || !!searchParams.get("department");

  return (
    <Form {...form}>
      <form className="space-y-4 mb-6">
        <div className="flex flex-col md:flex-row gap-4 items-end">
          {/* Status Filter */}
          <FormField
            control={form.control}
            name="status"
            render={({ field }) => (
              <FormItem>
                <Select
                  value={field.value}
                  onValueChange={(value) => {
                    field.onChange(value);
                    handleStatusChange(value);
                  }}
                >
                  <FormControl>
                    <SelectTrigger className="w-[180px]">
                      <SelectValue placeholder="Status" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="ALL">All Statuses</SelectItem>
                    <SelectItem value="ACTIVE">Active</SelectItem>
                    <SelectItem value="INACTIVE">Inactive</SelectItem>
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />

          {/* Department Filter */}
          <FormField
            control={form.control}
            name="department"
            render={({ field }) => (
              <FormItem>
                <Select
                  value={field.value}
                  onValueChange={(value) => {
                    field.onChange(value);
                    handleDepartmentChange(value);
                  }}
                >
                  <FormControl>
                    <SelectTrigger className="w-[220px]">
                      <SelectValue placeholder="Department" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {departments.map((dept) => (
                      <SelectItem key={dept.value} value={dept.value}>
                        {dept.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />

          {/* Reset Button */}
          {areFiltersApplied && (
            <Button
              type="button"
              variant="outline"
              onClick={handleReset}
              className="flex items-center gap-1"
            >
              <X className="h-4 w-4" />
              Reset Filters
            </Button>
          )}
        </div>
      </form>
    </Form>
  );
}