import { useNavigate, useSearchParams } from "react-router-dom";
import ThreeDotsLoader from "@/components/common/three-dots-loader";
import { CalendarDays } from "lucide-react";
import { Card, CardContent } from "@/components/ui/card";
import useBundles from "@/features/bundles/hooks/useBundles";
import { useState, useMemo } from "react";
import BundleCard from "@/features/bundles/ui/bundle-card";
import CustomPaginationComponent from "@/components/common/custom-pagination-component";
import VaulDrawer from "@/components/ui/drawer";
import AddEditBundlesForm from "@/features/bundles/ui/bundle-form";
import BundleFilter from "@/features/bundles/ui/bundle-filter";

interface BundleFilters {
  status: string;
  category: string;
}

export default function Bundles() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const page = parseInt(searchParams.get("page") || "0", 10);
  const status = searchParams.get("status") || "ALL";
  const category = searchParams.get("category") || "ALL";

  const product_id = category !== "ALL" ? category : undefined;
  const statusParam = status !== "ALL" ? status : undefined;

  const currentFilters = useMemo(() => ({
    status,
    category,
  }), [status, category]);

  const { isFetching, isLoading, isSuccess, data, error } = useBundles(
    page,
    statusParam,
    product_id
  );

  const [showBundleFormDrawer, setShowBundleFormDrawer] = useState(false);
  const [selectedBundle, setSelectedBundle] = useState<any>(null);

  if (error) {
    return <></>;
  }

  if (isFetching || isLoading || !data) {
    return <ThreeDotsLoader />;
  }

  const { bundles, lastPage: lastSkip, currentPage, total } = data;
  const lastPage = lastSkip + 1;

  const handlePageChange = (page: number) => {
    if (page >= 0 && page < lastPage) {
      const newParams = new URLSearchParams(searchParams);
      newParams.set("page", page.toString());
      navigate(`?${newParams.toString()}`, { replace: true });
    }
  };

  const handleApplyFilters = (filters: BundleFilters) => {
    const newParams = new URLSearchParams();
    newParams.set("page", "0");

    if (filters.status && filters.status !== "ALL") {
      newParams.set("status", filters.status);
    }

    if (filters.category && filters.category !== "ALL") {
      newParams.set("category", filters.category);
    }

    setSearchParams(newParams);
  };

  return (
    <div className="space-y-6 pt-6 px-6">
      <BundleFilter 
        onApplyFilters={handleApplyFilters} 
        currentFilters={currentFilters}
      />
      
      {isSuccess && (
        <BundleCard
          bundles={bundles}
          setShowBundleDrawer={setShowBundleFormDrawer}
          setSelectedBundle={setSelectedBundle}
        />
      )}
      
      {bundles.length === 0 && (
        <Card>
          <CardContent className="flex flex-col items-center justify-center py-8">
            <CalendarDays className="h-8 w-8 text-muted-foreground" />
            <p className="mt-2 text-center text-sm text-muted-foreground">
              No bundles found
            </p>
          </CardContent>
        </Card>
      )}

      {bundles?.length > 0 && (
        <CustomPaginationComponent
          currentPage={currentPage}
          lastPage={lastPage}
          onPageChange={handlePageChange}
        />
      )}

      <VaulDrawer
        drawerDirection="right"
        isOpen={showBundleFormDrawer}
        onOpenChange={setShowBundleFormDrawer}
        triggerChildren={null}
        contentChildren={
          <AddEditBundlesForm
            editMode={selectedBundle ? true : false}
            onClose={() => setShowBundleFormDrawer(false)}
            initialData={selectedBundle}
          />
        }
      />
    </div>
  );
}