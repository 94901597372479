import axiosInstance from "@/api/axios-instance";

export async function updateUsageException(usage_id: string) {
  const response = await axiosInstance.post("/usage/exception", {
    usage_id,
  });

  if (!response.status.toString().startsWith("2")) {
    throw new Error("failed updating exception");
  }

  const data = response.data;
  return data;
}
