import { useEffect } from "react";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { X } from "lucide-react";
import { useForm } from "react-hook-form";
import { useProducts } from "@/features/forms/hooks/use-products";
import { Product } from "@/pages/forms/types/product";

interface BundleFilters {
  status: string;
  category: string;
}

interface BundleFilterProps {
  onApplyFilters: (filters: BundleFilters) => void;
  currentFilters: BundleFilters;
}

export default function BundleFilter({ onApplyFilters, currentFilters }: BundleFilterProps) {
  const { data: products, isLoading: productsLoading } = useProducts();
  
  const productOptions = !productsLoading && products ? 
    [
      { value: "ALL", label: "All Categories" },
      ...products.map((product: Product) => ({
        value: product.id,
        label: product.name,
      }))
    ] : 
    [{ value: "ALL", label: "All Categories" }];

  const form = useForm<BundleFilters>({
    defaultValues: currentFilters,
  });

  const handleStatusChange = (newStatus: string) => {
    const currentValues = form.getValues();
    onApplyFilters({
      ...currentValues,
      status: newStatus,
    });
  };

  const handleCategoryChange = (newCategory: string) => {
    const currentValues = form.getValues();
    onApplyFilters({
      ...currentValues,
      category: newCategory,
    });
  };

  const handleReset = () => {
    form.reset({
      status: "ALL",
      category: "ALL",
    });

    onApplyFilters({
      status: "ALL",
      category: "ALL",
    });
  };

  useEffect(() => {
    form.reset(currentFilters);
  }, [currentFilters, form]);

  const areFiltersApplied =
    currentFilters.status !== "ALL" || currentFilters.category !== "ALL";

  return (
    <Form {...form}>
      <form className="space-y-4 mb-6">
        <div className="flex flex-col md:flex-row gap-4 items-end">
          {/* Status */}
          <FormField
            control={form.control}
            name="status"
            render={({ field }) => (
              <FormItem>
                <Select
                  value={field.value}
                  onValueChange={(value) => {
                    field.onChange(value);
                    handleStatusChange(value);
                  }}
                >
                  <FormControl>
                    <SelectTrigger className="w-[180px]">
                      <SelectValue placeholder="Status" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="ALL">All</SelectItem>
                    <SelectItem value="ACTIVE">Active</SelectItem>
                    <SelectItem value="INACTIVE">Inactive</SelectItem>
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />

          {/* Category */}
          <FormField
            control={form.control}
            name="category"
            render={({ field }) => (
              <FormItem>
                <Select
                  value={field.value}
                  onValueChange={(value) => {
                    field.onChange(value);
                    handleCategoryChange(value);
                  }}
                  disabled={productsLoading}
                >
                  <FormControl>
                    <SelectTrigger className="w-[220px]">
                      <SelectValue placeholder="Category" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {productOptions.map((product) => (
                      <SelectItem key={product.value} value={product.value}>
                        {product.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />

          {/* Reset option */}
          {areFiltersApplied && (
            <Button
              type="button"
              variant="outline"
              onClick={handleReset}
              className="flex items-center gap-1"
            >
              <X className="h-4 w-4" />
              Reset Filters
            </Button>
          )}
        </div>
      </form>
    </Form>
  );
}