import ThreeDotsLoader from "@/components/common/three-dots-loader";
import useForms from "@/features/forms/hooks/use-forms";
import FormCard from "@/features/forms/ui/form-card";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomPaginationComponent from "@/components/common/custom-pagination-component";
import FormFilter from "@/features/forms/ui/filter-forms";


// Define the filter interface
interface FormFilters {
  status: string;
  department: string;
}

export default function Forms() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // Get page and filter values from URL params
  const page = parseInt(searchParams.get("page") || "0", 10);
  const status = searchParams.get("status") || undefined;
  const department = searchParams.get("department") || undefined;

  // Fetch forms with filters
  const { isFetching, isLoading, isSuccess, data, error } = useForms(
    page,
    undefined, // No search parameter for now
    status,
    department
  );

  if (error) {
    return <></>;
  }

  if (isFetching || isLoading || !data) {
    return <ThreeDotsLoader />;
  }

  const { forms, lastPage: lastSkip, currentPage, total } = data;
  const lastPage = lastSkip + 1;

  const handlePageChange = (page: number) => {
    if (page >= 0 && page < lastPage) {
      // Keep existing filters when changing page
      const newParams = new URLSearchParams(searchParams);
      newParams.set("page", page.toString());
      navigate(`?${newParams.toString()}`, { replace: true });
    }
  };

  const handleApplyFilters = (filters: FormFilters) => {
    // This is handled by the FormFilter component through URL params
    // We just need to ensure we're on page 0
    const newParams = new URLSearchParams();
    newParams.set("page", "0");

    if (filters.status && filters.status !== "ALL") {
      newParams.set("status", filters.status);
    }

    if (filters.department && filters.department !== "ALL") {
      newParams.set("department", filters.department);
    }

    navigate(`?${newParams.toString()}`, { replace: true });
  };

  return (
    <div className="space-y-6 pt-6 px-6">
      {/* Add the filter component */}
      <FormFilter onApplyFilters={handleApplyFilters} />
        <>
          <FormCard forms={forms} />

          <CustomPaginationComponent
            currentPage={currentPage}
            lastPage={lastPage}
            onPageChange={handlePageChange}
          />
        </>    
     
    </div>
  );
}
