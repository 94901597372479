import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  couponSchema,
  CouponFormData,
  StatusEnum,
  CouponData,
  CouponTypeEnum,
} from "../schema/coupon-schema";
import { useCreateCoupon } from "../hooks/use-create-coupon";
import { useUpdateCoupon } from "../hooks/use-update-coupon";
import { useUpdateCouponStatus } from "../hooks/use-update-coupon-status";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useState } from "react";
import { toast } from "@/hooks/use-toast";
import { useActiveBundles } from "../hooks/use-active-bundles";
import { Bundle } from "@/pages/bundles/types/bundle";

type AddEditCouponProps = {
  editMode?: boolean;
  setShowCouponDrawer?: (value: boolean) => void;
  onClose?: () => void;
  initialData?: CouponData;
};

export default function AddEditCouponForm({
  editMode,
  setShowCouponDrawer,
  onClose,
  initialData,
}: AddEditCouponProps) {
  const form = useForm<CouponFormData>({
    resolver: zodResolver(couponSchema),
    defaultValues: initialData
      ? {
          ...initialData,
          bundle_ids: initialData.bundles?.map((bundle) => bundle.id) || [],
        }
      : {
          first_time_user_only: false,
          type: CouponTypeEnum.B2B,
          status: StatusEnum.ACTIVE,
        },
  });

  const createCouponMutation = useCreateCoupon();
  const updateCouponMutation = useUpdateCoupon();
  const updateStatusMutation = useUpdateCouponStatus();

  const [isPercentageDiscount, setIsPercentageDiscount] = useState(
    Boolean(initialData?.discount_percentage)
  );

  const onSubmit = async (data: CouponFormData) => {
    try {
      const formData = {
        ...data,
        discount_percentage: isPercentageDiscount
          ? data.discount_percentage
          : null,
        discount_amount: !isPercentageDiscount ? data.discount_amount : null,
        bundle_ids: data.bundle_ids || [],
        // description: data.description || undefined
      };

      if (editMode && initialData?.id) {
        await updateCouponMutation.mutateAsync({
          id: initialData.id,
          data: formData,
        });
      } else {
        await createCouponMutation.mutateAsync(formData);
      }
      setShowCouponDrawer?.(false);
    } catch (error) {
      console.error("Error submitting form:", error);
      toast({ title: "Error Submitting the form", variant: "destructive" });
    }
  };

  const handleStatusChange = async () => {
    if (editMode && initialData?.id) {
      const newStatus =
        initialData.status === StatusEnum.ACTIVE
          ? StatusEnum.INACTIVE
          : StatusEnum.ACTIVE;
      await updateStatusMutation.mutateAsync({
        id: initialData.id,
        status: newStatus,
      });
      setShowCouponDrawer?.(false);
    }
  };

  const handleDiscountTypeChange = (value: "percentage" | "fixed") => {
    const isPercentage = value === "percentage";
    setIsPercentageDiscount(isPercentage);
    if (isPercentage) {
      form.setValue("discount_amount", null);
    } else {
      form.setValue("discount_percentage", null);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-10">
        <div className="bg-sidebar rounded h-16 flex items-center px-8">
          <h4 className="text-xl text-white">
            {editMode ? "Edit" : "Add"} Coupon
          </h4>
        </div>

        <div className="px-6 space-y-6">
          {/* Basic Information */}
          <div className="space-y-4">
            <h4>Basic Information</h4>
            <div className="grid grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        placeholder="Coupon Name"
                        {...field}
                        className="shadow-none drop-shadow-none"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="type"
                render={({ field }) => (
                  <FormItem>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <SelectTrigger className="shadow-none drop-shadow-none">
                        <SelectValue placeholder="Select Type" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value={CouponTypeEnum.INTERNAL}>
                          INTERNAL
                        </SelectItem>
                        <SelectItem value={CouponTypeEnum.CAMPAIGN}>
                          CAMPAIGN
                        </SelectItem>
                        <SelectItem value={CouponTypeEnum.COMMUNITY}>
                          COMMUNITY
                        </SelectItem>
                        <SelectItem value={CouponTypeEnum.B2B}>B2B</SelectItem>
                        <SelectItem value={CouponTypeEnum.B2B2C}>
                          B2B2C
                        </SelectItem>
                        <SelectItem value={CouponTypeEnum.AFFILIATE}>
                          AFFILIATE
                        </SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>

          {/* Discount Settings */}
          <div className="space-y-4">
            <h4>Discount Settings</h4>
            <div className="grid grid-cols-2 gap-4">
              {/* Discount Type Selection */}
              <FormItem>
                <FormLabel className="text-sm font-medium">
                  Discount Type <span className="text-red-500">*</span>
                </FormLabel>
                <Select
                  onValueChange={handleDiscountTypeChange}
                  defaultValue={isPercentageDiscount ? "percentage" : "fixed"}
                  value={isPercentageDiscount ? "percentage" : "fixed"}
                >
                  <SelectTrigger className="shadow-none drop-shadow-none">
                    <SelectValue placeholder="Select discount type" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="percentage">Percentage</SelectItem>
                    <SelectItem value="fixed">Fixed Amount</SelectItem>
                  </SelectContent>
                </Select>
              </FormItem>

              <FormField
                control={form.control}
                name={
                  isPercentageDiscount
                    ? "discount_percentage"
                    : "discount_amount"
                }
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-sm font-medium">
                      {isPercentageDiscount
                        ? "Discount Percentage"
                        : "Discount Amount"}{" "}
                      <span className="text-red-500">*</span>
                    </FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        className="shadow-none drop-shadow-none"
                        placeholder={
                          isPercentageDiscount
                            ? "Enter percentage (max 100)"
                            : "Enter amount"
                        }
                        {...field}
                        onChange={(e) => {
                          const value = e.target.value;
                          field.onChange(
                            value === "" ? null : parseFloat(value)
                          );
                        }}
                        value={field.value ?? ""}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="discount_min"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-sm font-medium">
                      Minimum Amount
                    </FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        placeholder="Enter minimum amount"
                        className="shadow-none drop-shadow-none"
                        {...field}
                        onChange={(e) => {
                          const value = e.target.value;
                          field.onChange(
                            value === "" ? null : parseFloat(value)
                          );
                        }}
                        value={field.value ?? ""}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="discount_max"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-sm font-medium">
                      Maximum Discount
                    </FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        className="shadow-none drop-shadow-none"
                        placeholder="Enter maximum discount"
                        {...field}
                        onChange={(e) => {
                          const value = e.target.value;
                          field.onChange(
                            value === "" ? null : parseFloat(value)
                          );
                        }}
                        value={field.value ?? ""}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="discount_cap"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-sm font-medium">
                      Discount Cap
                    </FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        className="shadow-none drop-shadow-none"
                        placeholder="Enter discount cap"
                        {...field}
                        onChange={(e) => {
                          const value = e.target.value;
                          field.onChange(
                            value === "" ? null : parseFloat(value)
                          );
                        }}
                        value={field.value ?? ""}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>

          {/* Date Range */}
          <div className="space-y-4">
            <h4>Validity Period</h4>
            <div className="grid grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="start_date"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        type="datetime-local"
                        className="shadow-none drop-shadow-none"
                        {...field}
                        value={
                          field.value
                            ? new Date(field.value)
                                .toLocaleString("sv")
                                .slice(0, 16)
                            : ""
                        }
                        onChange={(e) => {
                          try {
                            const date = new Date(e.target.value);
                            if (!isNaN(date.getTime())) {
                              field.onChange(date.toISOString());
                            }
                          } catch (error) {
                            console.error("Invalid date:", error);
                          }
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="end_date"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        type="datetime-local"
                        className="shadow-none drop-shadow-none"
                        {...field}
                        value={
                          field.value
                            ? new Date(field.value)
                                .toLocaleString("sv")
                                .slice(0, 16)
                            : ""
                        }
                        onChange={(e) => {
                          if (!e.target.value) {
                            field.onChange(null);
                            return;
                          }
                          try {
                            const date = new Date(e.target.value);
                            if (!isNaN(date.getTime())) {
                              field.onChange(date.toISOString());
                            }
                          } catch (error) {
                            console.error("Invalid date:", error);
                          }
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>

          {/* Bundle Selection */}
          <div className="space-y-4">
            <h4>Associated Bundles</h4>

            <FormField
              control={form.control}
              name="bundle_ids"
              render={({ field }) => {
                const { data } = useActiveBundles();
                const bundles = data?.bundles || [];

                return (
                  <FormItem>
                    <FormControl>
                      <div className="space-y-2">
                        <Select
                          onValueChange={(value) => {
                            const currentValues = field.value || [];
                            if (value === "all") {
                              field.onChange([
                                ...bundles.map((x: any) => x.id),
                              ]);
                            } else if (!currentValues.includes(value)) {
                              field.onChange([...currentValues, value]);
                            }
                          }}
                        >
                          <SelectTrigger className="shadow-none drop-shadow-none">
                            <SelectValue placeholder="Select Bundles" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem key="all" value="all">
                              Select All
                            </SelectItem>
                            {bundles.map((bundle: Bundle) => (
                              <SelectItem key={bundle.id} value={bundle.id}>
                                {bundle.name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>

                        {field.value && field.value.length > 0 && (
                          <div className="flex flex-wrap gap-2 mt-2">
                            {field.value.map((selectedId) => {
                              const bundle = bundles.find(
                                (b: Bundle) => b.id === selectedId
                              );
                              return (
                                bundle && (
                                  <div
                                    key={selectedId}
                                    className="flex items-center gap-2 bg-gray-100 px-3 py-1 rounded-full"
                                  >
                                    <span className="text-sm">
                                      {bundle.name}
                                    </span>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        const newValues =
                                          field.value?.filter(
                                            (id) => id !== selectedId
                                          ) || [];
                                        field.onChange(newValues);
                                      }}
                                      className="text-red-500 hover:text-red-700"
                                    >
                                      ×
                                    </button>
                                  </div>
                                )
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
          </div>

          {/* Coupon internal description */}
          <div className="space-y-4">
            <h4>Description</h4>
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder="Internal description"
                      className="shadow-none drop-shadow-none"
                      {...field}
                      value={field.value ?? ""}
                      onChange={(e) => {
                        const value = e.target.value;
                        field.onChange(value === "" ? null : value); // Convert empty string to null
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          {/* Settings */}
          <div className="space-y-4">
            <h4>Settings</h4>
            <FormField
              control={form.control}
              name="first_time_user_only"
              render={({ field }) => (
                <FormItem className="flex items-center gap-x-2">
                  <FormControl className="">
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormLabel className="pb-1.5">First Time User Only</FormLabel>
                </FormItem>
              )}
            />
          </div>

          {/* CTAs */}
          <div className="flex justify-end gap-4 pt-24">
            {editMode && (
              <>
                {initialData?.status === "ACTIVE" ? (
                  <Button
                    type="button"
                    variant="destructive"
                    onClick={handleStatusChange}
                    disabled={updateStatusMutation.isPending}
                  >
                    Inactive
                  </Button>
                ) : (
                  <Button
                    type="button"
                    onClick={handleStatusChange}
                    disabled={updateStatusMutation.isPending}
                    className="bg-green-600 hover:bg-green-700 px-8"
                  >
                    Active
                  </Button>
                )}
              </>
            )}
            <Button
              type="submit"
              disabled={
                createCouponMutation.isPending || updateCouponMutation.isPending
              }
              className="bg-sidebar px-8 hover:bg-sidebar"
            >
              {editMode ? "Update" : "Create"}
            </Button>
          </div>
        </div>
      </form>
    </Form>
  );
}
