import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useForm } from "react-hook-form";
import { Search, X } from "lucide-react";
import { StatusEnum } from "../schema/coupon-schema";

// Define the filter form interface
interface CouponFilters {
  search: string;
  status: string;
}

interface CouponFilterProps {
  onApplyFilters: (filters: CouponFilters) => void;
}

export default function CouponFilter({ onApplyFilters }: CouponFilterProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  // Initialize filters from URL params
  const initialStatus = searchParams.get("status") || "ALL";

  const initialFilters: CouponFilters = {
    search: searchParams.get("search") || "",
    status: initialStatus,
  };

  // Initialize form with values from URL
  const form = useForm<CouponFilters>({
    defaultValues: initialFilters,
  });

  // Apply filters to URL and trigger callback
  const applyFilters = (values: CouponFilters) => {
    const newParams = new URLSearchParams(searchParams);

    // Reset to page 0 when filters change
    newParams.set("page", "0");

    if (values.search) {
      newParams.set("search", values.search);
    } else {
      newParams.delete("search");
    }

    if (values.status && values.status !== "ALL") {
      newParams.set("status", values.status);
    } else {
      newParams.delete("status");
    }

    setSearchParams(newParams);

    // Call the parent's callback
    onApplyFilters(values);
  };

  // Handle form submission from search
  const onSubmit = (values: CouponFilters) => {
    applyFilters(values);
  };

  // Reset all filters
  const handleReset = () => {
    form.reset({
      search: "",
      status: "ALL",
    });

    const newParams = new URLSearchParams();
    newParams.set("page", "0");
    setSearchParams(newParams);

    onApplyFilters({
      search: "",
      status: "ALL",
    });
  };

  // Handle direct status changes
  const handleStatusChange = (newStatus: string) => {
    const currentValues = form.getValues();
    applyFilters({
      ...currentValues,
      status: newStatus,
    });
  };

  // Update form when URL changes
  useEffect(() => {
    const newStatus = searchParams.get("status") || "ALL";

    form.reset({
      search: searchParams.get("search") || "",
      status: newStatus,
    });
  }, [searchParams, form]);

  // Check if any filters are applied
  const areFiltersApplied =
    !!searchParams.get("search") || !!searchParams.get("status");

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 mb-6">
        <div className="flex flex-col md:flex-row gap-4 items-end">
          <FormField
            control={form.control}
            name="search"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormControl>
                  <div className="relative">
                    <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
                    <Input
                      placeholder="Search by coupon name..."
                      className="pl-8"
                      {...field}
                    />
                  </div>
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="status"
            render={({ field }) => (
              <FormItem>
                <Select
                  value={field.value}
                  onValueChange={(value) => {
                    field.onChange(value);
                    handleStatusChange(value);
                  }}
                >
                  <FormControl>
                    <SelectTrigger className="w-[180px]">
                      <SelectValue placeholder="Status" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="ALL">All Status</SelectItem>
                    <SelectItem value={StatusEnum.ACTIVE}>Active</SelectItem>
                    <SelectItem value={StatusEnum.INACTIVE}>
                      Inactive
                    </SelectItem>
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />

          <div className="flex gap-2">
            <Button type="submit" className="bg-sidebar">
              Apply Filters
            </Button>

            {areFiltersApplied && (
              <Button
                type="button"
                variant="outline"
                onClick={handleReset}
                className="flex items-center gap-1"
              >
                <X className="h-4 w-4" />
                Reset
              </Button>
            )}
          </div>
        </div>
      </form>
    </Form>
  );
}
