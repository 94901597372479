import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateUsageException } from "../services/update-usage-exception";
import { useToast } from "@/hooks/use-toast";

export function useUsageException() {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation({
    mutationFn: (usage_id: string) => updateUsageException(usage_id),
    onSuccess: (data) => {
      const exception = data.allow_schedule_exception;
      queryClient.invalidateQueries({
        queryKey: [`user`],
        exact: false,
      });
      toast({
        title: "Success",
        description: exception
          ? "Succesfully Allowed Exception"
          : "Succesfully Disabled Exception",
      });
    },
  });
}
