import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useCreateForm } from "@/features/forms/hooks/use-create-form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { CreateFormData } from "@/pages/forms/types/form";
import { Bundle } from "@/pages/bundles/types/bundle";
import { useActiveBundles } from "@/features/coupons/hooks/use-active-bundles";

interface AddEditFormDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

const formSchema = z.object({
  department: z.string().min(1, "Department is required"),
  source_id: z.string().min(1, "Source ID is required"),
  status: z.enum(["ACTIVE", "INACTIVE"]),
  doc_link: z.string().min(1, "Documentation link is required"),
  bundle_ids: z.array(z.string()).default([]),
});

export default function AddEditFormDialog({
  open,
  onOpenChange,
}: AddEditFormDialogProps) {
  const createFormMutation = useCreateForm();
  const { data: bundleData, isLoading: bundlesLoading } = useActiveBundles();

  const bundles = bundleData?.bundles;

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      department: "",
      source_id: "",
      status: "ACTIVE",
      doc_link: "",
      bundle_ids: [],
    },
  });

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    try {
      // @ts-ignore
      await createFormMutation.mutateAsync(data as CreateFormData);
      form.reset();
      onOpenChange(false);
    } catch (error) {
      console.error("Error creating form:", error);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Add New Form</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <FormField
              control={form.control}
              name="department"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Department</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Enter department"
                      {...field}
                      className="w-full"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="source_id"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Source ID</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Enter Typeform source ID"
                      {...field}
                      className="w-full"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="status"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Status</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select status" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="ACTIVE">Active</SelectItem>
                      <SelectItem value="INACTIVE">Inactive</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            {/* Bundles Multi-select */}
            <FormField
              control={form.control}
              name="bundle_ids"
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>Bundles</FormLabel>
                    <FormControl>
                      <div className="space-y-2">
                        <Select
                          onValueChange={(value) => {
                            const currentValues = field.value || [];
                            if (!currentValues.includes(value)) {
                              field.onChange([...currentValues, value]);
                            }
                          }}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Select Bundles" />
                          </SelectTrigger>
                          <SelectContent>
                            {bundles?.map((bundle: Bundle) => (
                              <SelectItem key={bundle.id} value={bundle.id}>
                                {bundle.name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>

                        <div className="flex flex-wrap gap-2">
                          {field.value?.map((selectedId) => {
                            const selectedBundle = bundles?.find(
                              (b: Bundle) => b.id === selectedId
                            );
                            return (
                              selectedBundle && (
                                <div
                                  key={selectedId}
                                  className="flex items-center gap-2 bg-gray-100 px-3 py-1 rounded-full"
                                >
                                  <span className="text-sm">
                                    {selectedBundle.name}
                                  </span>
                                  <button
                                    type="button"
                                    onClick={() => {
                                      field.onChange(
                                        field.value.filter(
                                          (id) => id !== selectedId
                                        )
                                      );
                                    }}
                                    className="text-red-500 hover:text-red-700"
                                  >
                                    ×
                                  </button>
                                </div>
                              )
                            );
                          })}
                        </div>
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />

            <FormField
              control={form.control}
              name="doc_link"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Doc Link</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Enter documentation link"
                      {...field}
                      className="w-full"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button
              type="submit"
              className="w-full bg-sidebar hover:bg-orange-600"
              disabled={createFormMutation.isPending || bundlesLoading}
            >
              {createFormMutation.isPending ? "Creating..." : "Create Form"}
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
