import axiosInstance from "@/api/axios-instance";
import { CreateBundleRequest } from "@/pages/bundles/types/bundle";

export async function createBundle(data: CreateBundleRequest) {
  const response = await axiosInstance.post("/bundle", data);

  if (!response.status.toString().startsWith("2")) {
    throw new Error("Failed to create bundle");
  }

  return response.data;
}
