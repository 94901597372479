import { useQuery } from "@tanstack/react-query";
import { getSession } from "../services/get-session";
import { Session } from "@/pages/sessions/types/session";

export default function useSession(sessionId: string) {
  const { data, isLoading, error, isFetching } = useQuery<Session, Error>({
    queryKey: ["session", sessionId],
    queryFn: () => getSession(sessionId),
    enabled: !!sessionId,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 30,
  });

  return { data, isLoading, error, isFetching };
}
