import { useSearchParams } from "react-router-dom";
import useUsers from "../hooks/use-users";
import ThreeDotsLoader from "@/components/common/three-dots-loader";
import UserCard from "./user-card";
import SearchBar from "@/components/common/search-bar";
import CustomPaginationComponent from "@/components/common/custom-pagination-component";
import UserFilters from "./user-filter";

const UserList = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const page = parseInt(searchParams.get("page") || "0", 10);
  const search = searchParams.get("search") || "";
  const is_lead = searchParams.get("is_lead") || "ALL";
  const product_id = searchParams.get("product_id") || "ALL";

  const { data, isLoading, isFetching, isSuccess, error } = useUsers({
    page,
    search,
    is_lead:
      is_lead === "ALL"
        ? undefined
        : is_lead === "true"
        ? true
        : is_lead === "false"
        ? false
        : undefined,
    product_id: product_id === "ALL" ? undefined : product_id,
  });

  if (error) {
    return <></>;
  }

  if (isFetching || isLoading || !data) {
    return <ThreeDotsLoader />;
  }

  const { users, lastPage: lastSkip, currentPage } = data;
  const lastPage = lastSkip + 1;

  return (
    <div className="space-y-6 pt-6 px-6">
      <div className="space-y-4">
        <SearchBar placeholder="Search Users.." />
        <UserFilters />
      </div>

      {isSuccess && users.length > 0 ? (
        users.map((user: any) => <UserCard key={user.id} user={user} />)
      ) : (
        <div className="text-center py-8 text-muted-foreground">
          No users found matching your criteria
        </div>
      )}

      {users?.length > 0 && (
        <CustomPaginationComponent
          currentPage={currentPage}
          lastPage={lastPage}
          onPageChange={(newPage) => {
            const newSearchParams = new URLSearchParams(searchParams);
            newSearchParams.set("page", newPage.toString());
            setSearchParams(newSearchParams);
          }}
        />
      )}
    </div>
  );
};

export default UserList;