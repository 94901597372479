import React, { useState, KeyboardEvent, ChangeEvent } from "react";
import { Input } from "@/components/ui/input";
import { X } from "lucide-react";

interface MultiSelectTagsProps {
  value: string[];
  onChange: (value: string[]) => void;
  className?: string;
  placeholder?: string;
  suggestedOptions?: string[];
}

const MultiSelectTags: React.FC<MultiSelectTagsProps> = ({
  value = [],
  onChange,
  className = "",
  placeholder = "Type a value and press Enter or ,",
  suggestedOptions = [],
}) => {
  const [inputValue, setInputValue] = useState<string>("");

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if ((e.key === "Enter" || e.key === ",") && inputValue.trim()) {
      e.preventDefault();
      addTag(inputValue.trim());
    } else if (e.key === "Backspace" && !inputValue && value.length > 0) {
      onChange(value.slice(0, -1));
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const removeTag = (tagToRemove: string) => {
    onChange(value.filter((tag) => tag !== tagToRemove));
  };

  const addTag = (tag: string) => {
    if (tag && !value.includes(tag)) {
      onChange([...value, tag]);
    }
    setInputValue("");
  };

  return (
    <div className={`w-full ${className}`}>
      <div className="w-full border rounded py-2 bg-white px-2">
        <Input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          className="border-none shadow-none focus-visible:ring-0 focus:ring-0 h-8 focus:outline-none border-0 ring-0 outline-none p-0"
          placeholder={placeholder}
        />
      </div>

      {value.length > 0 && (
        <div className="mt-2">
          <p className="text-sm text-gray-500 mb-2">Added Values:</p>
          <div className="flex flex-wrap gap-2">
            {value.map((tag, index) => (
              <span
                key={index}
                className="bg-gray-100 text-gray-800 px-3 py-1 rounded-full text-sm flex items-center gap-x-1"
              >
                {tag}
                <button
                  type="button"
                  onClick={() => removeTag(tag)}
                  className="hover:bg-gray-200 rounded-full p-0.5 transition-colors"
                >
                  <X size={16} />
                </button>
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiSelectTags;
