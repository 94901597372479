import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { Switch } from "@/components/ui/switch";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { useCreateProfessional } from "../hooks/use-create-professional";
import { Professional } from "@/pages/professionals/types/professional";
import {
  professionalSchema,
  ProfessionalFormData,
  ProfessionalType,
  ProfessionalStatus,
  JobCommitment,
  professionalTypeLabels,
  professionalStatusLabels,
  jobCommitmentLabels,
} from "@/features/professionals/schema/professional-schema";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { toast } from "@/hooks/use-toast";
import { CreateProfessionalInput } from "../services/create-professional";
import MultiSelectTags from "./multi-select-tags";
import { useUpdateProfessional } from "../hooks/use-update-professional";
import FileUploadField from "./file-upload";
import LanguageTagsInput from "./language-selector";
import { useSupervisors } from "../hooks/use-supervisor";

const DEPARTMENTS = [
  "Therapy",
  "Psychiatry",
  "Women's Health",
  "Men's Sexual Health",
];
const SPECIALIZATIONS = [
  "Clinical Psychology",
  "Psychiatry",
  "Surgery",
  "Obstetrics and Gynecology",
];
const SEX_OPTIONS = ["F", "M"];
const PRONOUNS = ["They/Them", "He/Him", "She/Her"];

type AddEditProfessionalsProps = {
  onClose: () => void;
  initialData?: Professional | null;
  editMode?: boolean;
  onDelete?: (id: string) => void;
};

export default function AddEditProfessionalsForm({
  onClose,
  initialData,
  editMode,
  onDelete,
}: AddEditProfessionalsProps) {
  const createProfessional = useCreateProfessional();
  const updateProfessional = useUpdateProfessional();
  const { data: supervisors, isLoading, error } = useSupervisors();

  const form = useForm<ProfessionalFormData>({
    resolver: zodResolver(professionalSchema),
    mode: "onChange",
    defaultValues: {
      name: initialData?.name || "",
      type: initialData?.type || ProfessionalType.COUNSELING_PSYCHOLOGIST,
      sex: initialData?.sex || "",
      status: initialData?.status || ProfessionalStatus.ACTIVE,
      age: initialData?.age || null,
      email: initialData?.email || null,
      phone_number: initialData?.phone_number || null,
      registration_number: initialData?.registration_number || null,
      years_of_experience: initialData?.years_of_experience || null,
      personal_email: initialData?.personal_email || null,
      address: initialData?.address || null,
      area_of_expertise: initialData?.area_of_expertise || [],
      languages: initialData?.languages || [],
      department: initialData?.department || "",
      age_range_minor: initialData?.age_range_minor || [0, 17],
      age_range_adult: initialData?.age_range_adult || [18, 100],
      bio: initialData?.bio || null,
      country: initialData?.country || null,
      has_kid: initialData?.has_kid || null,
      is_lead: initialData?.is_lead || null,
      is_married: initialData?.is_married || null,
      is_supervisor: initialData?.is_supervisor || null,
      qualification: initialData?.qualification || [],
      specialization: initialData?.specialization || null,
      university_name: initialData?.university_name || null,
      preferred_notification_number:
        initialData?.preferred_notification_number || null,
      pronouns: initialData?.pronouns || null,
      supervisor_id: initialData?.supervisor_id || null,
      job_commitment: initialData?.job_commitment || JobCommitment.FULL_TIME,
      picture: initialData?.picture || "",
      signature: initialData?.signature || "",
      stamp: initialData?.stamp || "",
    },
  });

  const onSubmit = async (data: ProfessionalFormData) => {

    try {
      const mutationData: CreateProfessionalInput = {
        ...data,
        name: data.name.trim(),
        type: data.type,
        sex: data.sex.trim(),
        status: data.status || ProfessionalStatus.ACTIVE,
        job_commitment: data.job_commitment || JobCommitment.FULL_TIME,

        // Arrays with defaults
        age_range_minor: data.age_range_minor || [0, 17],
        age_range_adult: data.age_range_adult || [18, 100],
        languages: data.languages || [],

        // Numbers with null
        age: data.age || null,
        years_of_experience: data.years_of_experience || null,

        // Booleans with false defaults
        is_supervisor: Boolean(data.is_supervisor),
        is_lead: Boolean(data.is_lead),
        has_kid: Boolean(data.has_kid),
        is_married: Boolean(data.is_married),

        // Optional strings
        email: data.email || undefined,
        phone_number: data.phone_number || undefined,
        registration_number: data.registration_number || undefined,
        personal_email: data.personal_email || undefined,
        address: data.address || undefined,
        area_of_expertise: data.area_of_expertise || [],
        bio: data.bio || undefined,
        country: data.country || undefined,
        qualification: data.qualification || [],
        specialization: data.specialization || undefined,
        university_name: data.university_name || undefined,
        preferred_notification_number:
          data.preferred_notification_number || undefined,
        pronouns: data.pronouns || undefined,
        supervisor_id: data.supervisor_id || undefined,
        department: data.department || undefined,
      };

      if (editMode && initialData?.id) {
        await updateProfessional.mutateAsync({
          id: initialData.id,
          data: mutationData,
        });
      } else {
        await createProfessional.mutateAsync(mutationData);
      }
      toast({
        title: "Success",
        description: "Professional created successfully",
      });
      onClose();
    } catch (error) {
      console.error("Error submitting form:", error);
      toast({
        title: "Error",
        description: "Failed to create professional",
        variant: "destructive",
      });
    }
  };

  const {
    formState: { errors, isValid, isSubmitting },
  } = form;

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit, (errors) => {
          toast({
            title: "Validation Error",
            description: "Please check the form for errors",
            variant: "destructive",
          });
        })}
        className="bg-white h-fit pb-8 rounded-lg"
      >
        {/* error summary at the top - debugging ....  */}
        {Object.keys(errors).length > 0 && (
          <div className="p-4 mb-4 bg-red-50 text-red-500">
            <h5>Please fix the following errors:</h5>
            <ul>
              {Object.entries(errors).map(([key, error]) => (
                <li key={key}>
                  {key}: {error?.message as string}
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className="bg-sidebar rounded h-16 flex items-center px-8">
          <h4 className="text-xl text-white">
            {editMode ? "Edit" : "Add"} Professional
          </h4>
        </div>

        <div className="py-5 px-6 grid grid-cols-1 gap-y-7">
          {/* Basic Information */}
          <div>
            <h4>Basic Information</h4>
            <div className="mt-3 grid grid-cols-2 gap-x-2 gap-y-4">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Full Name</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Full Name"
                        className="shadow-none drop-shadow-none h-12"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="type"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Type</FormLabel>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <FormControl>
                        <SelectTrigger className="h-12">
                          <SelectValue placeholder="Select Type" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {Object.entries(professionalTypeLabels).map(
                          ([value, label]) => (
                            <SelectItem key={value} value={value}>
                              {label}
                            </SelectItem>
                          )
                        )}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="phone_number"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Phone Number</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="(Ex: +9189xxxxxxxx)"
                        className="shadow-none drop-shadow-none h-12"
                        {...field}
                        value={field.value || ""}
                        onChange={(e) => field.onChange(e.target.value || null)}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Professional Email"
                        type="email"
                        className="shadow-none drop-shadow-none h-12"
                        {...field}
                        value={field.value || ""}
                        onChange={(e) => field.onChange(e.target.value || null)}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="mt-4">
              {/* languages dropdown */}
              <FormField
                control={form.control}
                name="languages"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Languages</FormLabel>
                    <FormControl>
                      <LanguageTagsInput
                        value={field.value || []}
                        onChange={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>

          {/* Professional Details */}
          <div className="mt-3">
            <h4>Professional Details</h4>
            <div className="mt-3 grid grid-cols-2 gap-x-2 gap-y-4">
              <FormField
                control={form.control}
                name="department"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Department</FormLabel>
                    <Select
                      value={field.value || ""}
                      onValueChange={field.onChange}
                    >
                      <FormControl>
                        <SelectTrigger className="h-12">
                          <SelectValue placeholder="Select Department" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {DEPARTMENTS.map((dept) => (
                          <SelectItem key={dept} value={dept}>
                            {dept}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="specialization"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Specialization</FormLabel>
                    <Select
                      value={field.value || ""}
                      onValueChange={field.onChange}
                    >
                      <FormControl>
                        <SelectTrigger className="h-12">
                          <SelectValue placeholder="Select Specialization" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {SPECIALIZATIONS.map((spec) => (
                          <SelectItem key={spec} value={spec}>
                            {spec}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="qualification"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Qualification</FormLabel>
                    <FormControl>
                      <MultiSelectTags
                        value={field.value || []}
                        onChange={(values) => field.onChange(values)}
                        placeholder="Press Enter or add comma to add"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="years_of_experience"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Experience in years</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Years of Experience"
                        type="number"
                        min={0}
                        max={100}
                        className="shadow-none drop-shadow-none h-12"
                        {...field}
                        value={field.value || ""}
                        onChange={(e) =>
                          field.onChange(
                            e.target.value ? parseInt(e.target.value) : null
                          )
                        }
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="university_name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>University Name</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="University Name"
                        className="shadow-none drop-shadow-none h-12"
                        {...field}
                        value={field.value || ""}
                        onChange={(e) => field.onChange(e.target.value || null)}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="registration_number"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Registration Number</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Registration Number"
                        className="shadow-none drop-shadow-none h-12"
                        {...field}
                        value={field.value || ""}
                        onChange={(e) => field.onChange(e.target.value || null)}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="area_of_expertise"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Area of Expertise</FormLabel>
                    <FormControl>
                      <MultiSelectTags
                        value={field.value || []}
                        onChange={(values) => field.onChange(values)}
                        placeholder="ress Enter or add comma to add"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>

          {/* Personal Details */}
          <div className="mt-3">
            <h4>Personal Details</h4>
            <div className="mt-3 grid grid-cols-2 gap-x-2 gap-y-4">
              <FormField
                control={form.control}
                name="sex"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Sex</FormLabel>
                    <Select
                      value={field.value || ""}
                      onValueChange={field.onChange}
                    >
                      <FormControl>
                        <SelectTrigger className="h-12">
                          <SelectValue placeholder="Select Sex" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {SEX_OPTIONS.map((option) => (
                          <SelectItem key={option} value={option}>
                            {option}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="age"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Age</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Age"
                        type="number"
                        min={1}
                        className="shadow-none drop-shadow-none h-12"
                        {...field}
                        value={field.value || ""}
                        onChange={(e) =>
                          field.onChange(
                            e.target.value ? parseInt(e.target.value) : null
                          )
                        }
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="country"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Country</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Country"
                        className="shadow-none drop-shadow-none h-12"
                        {...field}
                        value={field.value || ""}
                        onChange={(e) => field.onChange(e.target.value || null)}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="address"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Address</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Address"
                        className="shadow-none drop-shadow-none h-12"
                        {...field}
                        value={field.value || ""}
                        onChange={(e) => field.onChange(e.target.value || null)}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="pronouns"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Pronouns</FormLabel>
                    <Select
                      value={field.value || ""}
                      onValueChange={field.onChange}
                    >
                      <FormControl>
                        <SelectTrigger className="h-12">
                          <SelectValue placeholder="Select Pronouns" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {PRONOUNS.map((option) => (
                          <SelectItem key={option} value={option}>
                            {option}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <br />
              <div className="flex items-center gap-x-6 mt-3">
                <FormField
                  control={form.control}
                  name="is_married"
                  render={({ field }) => (
                    <FormItem>
                      <div className="flex items-center gap-x-2">
                        <FormControl>
                          <Checkbox
                            checked={field.value || false}
                            onCheckedChange={field.onChange}
                            className="peer bg-transparent data-[state=checked]:bg-sidebar data-[state=checked]:border-transparent [&>span>svg]:fill-white [&>span>svg]:text-white"
                          />
                        </FormControl>
                        <label className="text-sm font-medium">Married</label>
                      </div>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="has_kid"
                  render={({ field }) => (
                    <FormItem>
                      <div className="flex items-center gap-x-2">
                        <FormControl>
                          <Checkbox
                            checked={field.value || false}
                            onCheckedChange={field.onChange}
                            className="peer bg-transparent data-[state=checked]:bg-sidebar data-[state=checked]:border-transparent [&>span>svg]:fill-white [&>span>svg]:text-white"
                          />
                        </FormControl>
                        <label className="text-sm font-medium">Has Kids</label>
                      </div>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
          </div>

          {/* Role and Status */}
          <div className="mt-3">
            <h4>Role & Status</h4>
            <div className="mt-3">
              {/* status dropdown  */}
              <div className="flex gap-x-3 gap-y-4">
                <FormField
                  control={form.control}
                  name="status"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Status</FormLabel>
                      <Select
                        value={field.value!}
                        onValueChange={field.onChange}
                      >
                        <FormControl>
                          <SelectTrigger className="w-[280px] drop-shadow-none shadow-none">
                            <SelectValue placeholder="Select Status" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {Object.entries(professionalStatusLabels).map(
                            ([value, label]) => (
                              <SelectItem key={value} value={value}>
                                {label}
                              </SelectItem>
                            )
                          )}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="job_commitment"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Job Commitment</FormLabel>
                      <Select
                        value={field.value!}
                        onValueChange={field.onChange}
                      >
                        <FormControl>
                          <SelectTrigger className="w-[280px] drop-shadow-none shadow-none">
                            <SelectValue placeholder="Job Commitment" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {Object.entries(jobCommitmentLabels).map(
                            ([value, label]) => (
                              <SelectItem key={value} value={value}>
                                {label}
                              </SelectItem>
                            )
                          )}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              {/* toggle fields  */}
              <div className="flex items-center gap-x-8 mt-6">
                <FormField
                  control={form.control}
                  name="is_supervisor"
                  render={({ field }) => (
                    <FormItem>
                      <div className="flex items-center gap-2">
                        <FormControl>
                          <Switch
                            checked={field.value || false}
                            onCheckedChange={field.onChange}
                            className="data-[state=checked]:bg-sidebar data-[state=checked]:border-sidebar"
                          />
                        </FormControl>
                        <label className="text-sm font-medium">
                          Supervisor
                        </label>
                      </div>

                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="is_lead"
                  render={({ field }) => (
                    <FormItem className="flex items-center gap-2">
                      <FormControl>
                        <Switch
                          checked={field.value || false}
                          onCheckedChange={field.onChange}
                          className="data-[state=checked]:bg-sidebar data-[state=checked]:border-sidebar"
                        />
                      </FormControl>
                      <label className="text-sm font-medium">Team Lead</label>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
          </div>

          {/* Additional Details */}
          <div className="mt-3">
            <h4>Additional Details</h4>
            <div className="mt-3 grid grid-cols-2 gap-x-2 gap-y-4">
              <div className="col-span-2">
                <FormField
                  control={form.control}
                  name="bio"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Bio</FormLabel>
                      <FormControl>
                        <textarea
                          placeholder="Bio"
                          className="w-full h-24 p-2 border rounded-md resize-none"
                          {...field}
                          value={field.value || ""}
                          onChange={(e) =>
                            field.onChange(e.target.value || null)
                          }
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <FormField
                control={form.control}
                name="preferred_notification_number"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Preferred Notification Number</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Preferred Notification Number"
                        className="shadow-none drop-shadow-none h-12"
                        {...field}
                        value={field.value || ""}
                        onChange={(e) => field.onChange(e.target.value || null)}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {supervisors?.length > 0 && (
                <FormField
                  control={form.control}
                  name="supervisor_id"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Select Supervisor</FormLabel>
                      <Select
                        value={field.value || ""}
                        onValueChange={field.onChange}
                      >
                        <FormControl>
                          <SelectTrigger className="h-12">
                            <SelectValue placeholder="Select Supervisor" />
                            {/* Select a supervisor */}
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {isLoading ? (
                            <SelectItem disabled value="">
                              Loading supervisors...
                            </SelectItem>
                          ) : supervisors && supervisors.length > 0 ? (
                            supervisors.map((supervisor: Professional) => (
                              <SelectItem
                                key={supervisor.id}
                                value={supervisor.id}
                              >
                                {supervisor.name}
                              </SelectItem>
                            ))
                          ) : (
                            <SelectItem disabled value="">
                              No supervisors available
                            </SelectItem>
                          )}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
            </div>
          </div>

          {/* Age Ranges */}
          <div className="mt-3">
            <h4>Age Ranges</h4>
            <div className="mt-3 flex justify-between">
              <div>
                <FormField
                  control={form.control}
                  name="age_range_minor"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Minor Age Range</FormLabel>
                      <div className="flex gap-2">
                        <FormControl>
                          <Input
                            type="number"
                            className="shadow-none drop-shadow-none h-12 w-[90px]"
                            value={field.value?.[0] || 0}
                            onChange={(e) => {
                              const value = parseInt(e.target.value);
                              field.onChange([value, field.value?.[1] || 17]);
                            }}
                            min={0}
                            max={17}
                          />
                        </FormControl>
                        <span className="flex items-center mx-2">to</span>
                        <FormControl>
                          <Input
                            type="number"
                            className="shadow-none drop-shadow-none h-12 w-[90px]"
                            value={field.value?.[1] || 17}
                            onChange={(e) => {
                              const value = parseInt(e.target.value);
                              field.onChange([field.value?.[0] || 0, value]);
                            }}
                            min={0}
                            max={17}
                          />
                        </FormControl>
                      </div>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div>
                <FormField
                  control={form.control}
                  name="age_range_adult"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Adult Age Range</FormLabel>
                      <div className="flex gap-2">
                        <FormControl>
                          <Input
                            type="number"
                            className="shadow-none drop-shadow-none h-12 w-[90px]"
                            value={field.value?.[0] || 18}
                            onChange={(e) => {
                              const value = parseInt(e.target.value);
                              field.onChange([value, field.value?.[1] || 100]);
                            }}
                            min={18}
                            max={100}
                          />
                        </FormControl>
                        <span className="flex items-center mx-2">to</span>
                        <FormControl>
                          <Input
                            type="number"
                            className="shadow-none drop-shadow-none h-12 w-[90px]"
                            value={field.value?.[1] || 100}
                            onChange={(e) => {
                              const value = parseInt(e.target.value);
                              field.onChange([field.value?.[0] || 18, value]);
                            }}
                            min={18}
                            max={100}
                          />
                        </FormControl>
                      </div>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
          </div>

          {/* Required Documents */}
          <div className="mt-3">
            <h4>Required Documents</h4>
            <div className="mt-3 grid grid-cols-2 gap-x-2 gap-y-4">
              <FormField
                control={form.control}
                name="picture"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormControl>
                      <FileUploadField
                        name="picture"
                        label="Picture"
                        isPicture={true}
                        value={field.value!}
                        onChange={field.onChange}
                        error={errors.picture?.message as string}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="signature"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormControl>
                      <FileUploadField
                        name="signature"
                        label="Signature"
                        value={field.value!}
                        onChange={field.onChange}
                        error={errors.signature?.message as string}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="stamp"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormControl>
                      <FileUploadField
                        name="stamp"
                        label="Stamp"
                        value={field.value!}
                        onChange={field.onChange}
                        error={errors.stamp?.message as string}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
          </div>

          {/* CTA */}
          <div className="flex items-end justify-end gap-x-4 mt-20">
            {editMode && onDelete && (
              <Button
                type="button"
                className="bg-red-500 w-32 h-10 hover:bg-red-500/80"
                onClick={() => initialData?.id && onDelete(initialData.id)}
              >
                Delete
              </Button>
            )}
            <Button
              type="submit"
              className="bg-sidebar text-white w-32 h-10 hover:bg-sidebar"
              disabled={
                createProfessional.isPending ||
                updateProfessional.isPending ||
                isSubmitting
              }
            >
              {createProfessional.isPending || updateProfessional.isPending
                ? "Saving..."
                : editMode
                ? "Update"
                : "Add"}
            </Button>
          </div>
        </div>
      </form>
    </Form>
  );
}
