import { useQuery } from "@tanstack/react-query";
import { getCoupons } from "../services/get-coupons";
import { Coupon } from "@/pages/coupons/types/coupon";

interface PaginatedCoupons {
  coupons: Array<Coupon>;
  lastPage: number;
  nextPage: number | null;
  currentPage: number;
  total: number;
}

export default function useCoupons(
  page: number = 0,
  search?: string,
  status?: string
) {

  const { data, isLoading, error, isFetching, isSuccess } =
    useQuery<PaginatedCoupons>({
      queryKey: [
        "coupons",
        {
          page,
          search,
          status,
        },
      ],
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 30, // 30 minutes
      queryFn: () => {
        return getCoupons({ page, search, status });
      },
    });
    
  if (error) {
    console.error("useCoupons hook error:", error);
  }

  return { data, isLoading, error, isFetching, isSuccess };
}
