import { useForm } from "react-hook-form";
import { format } from "date-fns";
import { CalendarIcon, Search } from "lucide-react";

import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { SearchFilters } from "@/pages/sessions/types/session";
import useAllProfessionals from "@/features/professionals/hooks/use-all-professional";
import { useProducts } from "@/features/forms/hooks/use-products";
import { Product } from "@/pages/forms/types/product";

interface SessionSearchProps {
  onSearch: (filters: SearchFilters) => void;
  initialFilters: SearchFilters;
}

export function SessionSearch({
  onSearch,
  initialFilters,
}: SessionSearchProps) {
  const form = useForm<SearchFilters>({
    defaultValues: initialFilters,
  });

  // Use the all professionals hook to get all professionals
  const { data: professionalsData, isLoading: professionalsLoading } = useAllProfessionals();
  const professionals = professionalsData?.professionals || [];

  const { data: products, isLoading: productsLoading } = useProducts();

  const productOptions = !productsLoading && products ? 
    [
      { value: "ALL", label: "All Categories" },
      ...products.map((product: Product) => ({
        value: product.id,
        label: product.name,
      }))
    ] : 
    [{ value: "ALL", label: "All Categories" }];

  const handleChange = () => {
    const data = form.getValues();
    onSearch(data);
  };

  const onSubmit = (data: SearchFilters) => {
    onSearch(data);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <div className="flex flex-col gap-4 md:flex-row md:items-center">
          <div className="flex-1">
            <FormField
              control={form.control}
              name="search"
              render={({ field }) => (
                <FormItem className="relative">
                  <FormControl>
                    <div className="relative">
                      <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
                      <Input
                        placeholder="Search by name, email, or phone..."
                        className="pl-8"
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          handleChange();
                        }}
                      />
                    </div>
                  </FormControl>
                </FormItem>
              )}
            />
          </div>

          <div className="flex items-center gap-2">
            <FormField
              control={form.control}
              name="dateRange"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          className={`w-[240px] justify-start text-left font-normal ${
                            !field.value.start && "text-muted-foreground"
                          }`}
                        >
                          <CalendarIcon className="mr-2 h-4 w-4" />
                          {field.value.start ? (
                            field.value.end ? (
                              <>
                                {format(field.value.start, "LLL dd, y")} -{" "}
                                {format(field.value.end, "LLL dd, y")}
                              </>
                            ) : (
                              format(new Date(field.value.start), "LLL dd, y")
                            )
                          ) : (
                            <span>Pick a date range</span>
                          )}
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        initialFocus
                        mode="range"
                        selected={{
                          from: field.value.start || undefined,
                          to: field.value.end || undefined,
                        }}
                        onSelect={(range) => {
                          field.onChange({
                            start: range?.from || null,
                            end: range?.to || null,
                          });
                          handleChange();
                        }}
                        numberOfMonths={2}
                      />
                    </PopoverContent>
                  </Popover>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="status"
              render={({ field }) => (
                <FormItem>
                  <Select
                    onValueChange={(value) => {
                      field.onChange(value);
                      handleChange();
                    }}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger className="w-[130px]">
                        <SelectValue placeholder="Select status" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="all">All Status</SelectItem>
                      <SelectItem value="active">Active</SelectItem>
                      <SelectItem value="canceled">Canceled</SelectItem>
                    </SelectContent>
                  </Select>
                </FormItem>
              )}
            />
          </div>
        </div>

        {/* Filters for Professional and Product Category */}
        <div className="flex flex-col gap-4 md:flex-row md:items-center">
          <FormField
            control={form.control}
            name="professionalId"
            render={({ field }) => (
              <FormItem className="flex-1">
                <Select
                  onValueChange={(value) => {
                    field.onChange(value);
                    handleChange();
                  }}
                  value={field.value || "ALL"}
                  disabled={professionalsLoading}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select professional" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="ALL">All Professionals</SelectItem>
                    {professionals &&
                      professionals.length > 0 &&
                      professionals.map((professional) => (
                        <SelectItem
                          key={professional.id}
                          value={professional.id}
                        >
                          {professional.name}
                        </SelectItem>
                      ))}
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="productCategory"
            render={({ field }) => (
              <FormItem className="flex-1">
                <Select
                  onValueChange={(value) => {
                    field.onChange(value);
                    handleChange();
                  }}
                  value={field.value || "ALL"}
                  disabled={productsLoading}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select product category" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {productOptions.map((product) => (
                      <SelectItem key={product.value} value={product.value}>
                        {product.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />
        </div>
      </form>
    </Form>
  );
}