import { useQuery } from "@tanstack/react-query";
import { getBundles } from "../services/get-bundles";
import { Bundle } from "@/pages/bundles/types/bundle";

interface PaginatedBundles {
  bundles: Array<Bundle>;
  lastPage: number;
  nextPage: number | null;
  currentPage: number;
  total: number;
}

export default function useBundles(
  page: number = 0,
  status?: string,
  product_id?: string
) {
  const { data, isLoading, error, isFetching, isSuccess } =
    useQuery<PaginatedBundles>({
      queryKey: [
        "bundles",
        {
          page,
          status,
          product_id,
        },
      ],
      queryFn: () => getBundles({ page, status, product_id }),
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 30, 
    });

  return { data, isLoading, error, isFetching, isSuccess };
}