import { useQuery } from "@tanstack/react-query";
import { Professional } from "@/pages/professionals/types/professional";
import { getAllProfessionals } from "../services/get-all-professional";

interface AllProfessionalsResponse {
  professionals: Array<Professional>;
}

export default function useAllProfessionals() {
  const { data, isLoading, error, isFetching, isSuccess } =
    useQuery<AllProfessionalsResponse>({
      queryKey: ["all-professionals"],
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 30, // 30 minutes
      queryFn: getAllProfessionals,
    });

  return { 
    data: data || { professionals: [] }, 
    isLoading, 
    error, 
    isFetching, 
    isSuccess 
  };
}