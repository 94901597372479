import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createForm } from "../services/create-form";
import { toast } from "@/hooks/use-toast";
import { Form } from "@/pages/forms/types/form";

export function useCreateForm() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Omit<Form, "id" | "created_at" | "updated_at">) =>
      createForm(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["forms"] });
      toast({ title: "Success", description: "Form created successfully" });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: error.message || "Failed to create form",
        variant: "destructive",
      });
    },
  });
}
