import { useSearchParams } from "react-router-dom";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { X } from "lucide-react";
import { useProducts } from "@/features/forms/hooks/use-products";
import { Product } from "@/pages/forms/types/product";

const USER_LEAD_TYPES = [
  { value: "ALL", label: "All" },
  { value: "true", label: "Lead" },
  { value: "false", label: "User" },
];

export default function UserFilters() {
  const [searchParams, setSearchParams] = useSearchParams();
  const is_lead = searchParams.get("is_lead") || "ALL";
  const product_id = searchParams.get("product_id") || "ALL";

  const { data: products, isLoading: productsLoading } = useProducts();

  // formating products for the dropdown
  const productOptions = !productsLoading && products ? 
    [
      { value: "ALL", label: "All Categories" },
      ...products.map((product: Product) => ({
        value: product.id,
        label: product.name,
      }))
    ] : 
    [{ value: "ALL", label: "All Categories" }];

  const handleLeadTypeChange = (value: string) => {
    if (value === "ALL") {
      searchParams.delete("is_lead");
    } else {
      searchParams.set("is_lead", value);
    }
    searchParams.set("page", "0");
    setSearchParams(searchParams);
  };

  const handleProductChange = (value: string) => {
    if (value === "ALL") {
      searchParams.delete("product_id");
    } else {
      searchParams.set("product_id", value);
    }
    searchParams.set("page", "0");
    setSearchParams(searchParams);
  };

  const handleReset = () => {
    searchParams.delete("is_lead");
    searchParams.delete("product_id");
    searchParams.set("page", "0");
    setSearchParams(searchParams);
  };

  const areFiltersApplied = is_lead !== "ALL" || product_id !== "ALL";

  return (
    <div className="flex flex-wrap items-center gap-4 my-4">
      <div className="flex flex-col gap-1">
        <span className="text-sm font-medium">User Type</span>
        <Select value={is_lead} onValueChange={handleLeadTypeChange}>
          <SelectTrigger className="w-32">
            <SelectValue placeholder="Select type" />
          </SelectTrigger>
          <SelectContent>
            {USER_LEAD_TYPES.map((type) => (
              <SelectItem key={type.value} value={type.value}>
                {type.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div className="flex flex-col gap-1">
        <span className="text-sm font-medium">Product</span>
        <Select
          value={product_id}
          onValueChange={handleProductChange}
          disabled={productsLoading}
        >
          <SelectTrigger className="w-48">
            <SelectValue
              placeholder={productsLoading ? "Loading..." : "Select product"}
            />
          </SelectTrigger>
          <SelectContent>
            {productOptions.map((product) => (
              <SelectItem key={product.value} value={product.value}>
                {product.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      {areFiltersApplied && (
        <Button
          variant="outline"
          size="sm"
          onClick={handleReset}
          className="ml-auto self-end"
        >
          <X className="h-4 w-4 mr-1" />
          Reset Filters
        </Button>
      )}
    </div>
  );
}