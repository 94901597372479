import { useQuery } from "@tanstack/react-query";
import axiosInstance from "@/api/axios-instance";

export function useSupervisors() {
  return useQuery({
    queryKey: ["professionals", { is_supervisor: true }],
    queryFn: async () => {
      const response = await axiosInstance.get("/professional", {
        params: {
          is_supervisor: true,
          status: 'ACTIVE'
        }
      });
      return response.data.professionals;
    }
  });
}