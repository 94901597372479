import { Bundle } from "@/pages/bundles/types/bundle";
import { z } from "zod";

export const CouponTypeEnum = {
  CAMPAIGN: "CAMPAIGN",
  INTERNAL: "INTERNAL",
  COMMUNITY: "COMMUNITY",
  B2B: "B2B",
  B2B2C: "B2B2C",
  AFFILIATE: "AFFILIATE",
} as const;

export const StatusEnum = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
} as const;

export const couponSchema = z
  .object({
    name: z.string().min(1, "Coupon name is required"),
    first_time_user_only: z.boolean().default(false),
    type: z.enum([
      "CAMPAIGN",
      "INTERNAL",
      "COMMUNITY",
      "B2B",
      "B2B2C",
      "AFFILIATE",
    ] as const),
    status: z.enum(["ACTIVE", "INACTIVE"] as const),
    discount_cap: z.number().int().optional().nullable(),
    discount_max: z.number().int().optional().nullable(),
    discount_min: z.number().int().min(1).optional().nullable(),
    discount_amount: z.number().int().min(1).optional().nullable(),
    discount_percentage: z.number().max(100).optional().nullable(),
    start_date: z.string().datetime(),
    end_date: z.string().datetime().optional().nullable(),
    bundle_ids: z.array(z.string()).optional(),
    description: z
      .string()
      .optional()
      .nullable()
      .transform((val) => (val === "" ? null : val)),
  })
  .superRefine((data, ctx) => {
    // Check if both discount fields are empty
    if (data.discount_amount === null && data.discount_percentage === null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Either discount percentage or fixed amount must be provided",
        path: ["discount_amount"],
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Either discount percentage or fixed amount must be provided",
        path: ["discount_percentage"],
      });
    }
  });

export type CouponData = {
  id: string;
  created_at: string;
  bundles?: Bundle[];
} & z.infer<typeof couponSchema>;

export type CouponFormData = z.infer<typeof couponSchema>;
