import axiosInstance from "@/api/axios-instance";

export async function getCoupons({
  page,
  search,
  status,
}: {
  page: number;
  search?: string;
  status?: string;
}) {
  // Build params object
  const params: Record<string, any> = { page };

  // Add filter parameters if they exist
  if (search) params.search = search;

  // Handle status parameter
  if (status && status !== "ALL") {
    params.status = status;
  }

  try {
    // Make the API request with detailed logging
    const response = await axiosInstance.get("/coupon", { params });
    if (!response.status.toString().startsWith("2")) {
      throw new Error("coupons are not loaded");
    }

    const {
      coupons,
      nextPage,
      lastPage,
      page: currentPage,
      total,
    } = response.data;

    return {
      coupons,
      nextPage,
      lastPage,
      currentPage,
      total,
    };
  } catch (error) {
    throw error;
  }
}
