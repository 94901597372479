import { Controller, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

type SearchBarProps = {
  placeholder?: string;
  initialSearch?: string;
  onSearch?: (search: string) => void;
};

type SearchFormInputs = {
  search: string;
  filterType: string;
};

const SearchBar = ({ placeholder }: SearchBarProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const FILTER_TYPES = ["email", "phone", "name"] as const;

  const getInitialValues = () => {
    const searchQuery = searchParams.get("search") || "";
    const filterType = FILTER_TYPES.find((filter) =>
      searchQuery.toLowerCase().startsWith(`${filter}:`)
    );

    return {
      filterType: filterType || "all",
      search: filterType
        ? searchQuery.slice(filterType.length + 1)
        : searchQuery,
    };
  };

  const { handleSubmit, control } = useForm<SearchFormInputs>({
    defaultValues: getInitialValues(),
    values: getInitialValues(),
  });

  const onSubmit = (data: SearchFormInputs) => {
    const newSearchParams = new URLSearchParams(searchParams);
    
    newSearchParams.set("page", "0");

    const searchQuery = data.search
      ? data.search.trim().length < 1
        ? ""
        : data.search.trim()
      : "";

    const finalSearch =
      data.filterType === "all"
        ? searchQuery
        : `${data.filterType}:${searchQuery}`;

    if (finalSearch) {
      newSearchParams.set("search", finalSearch);
    } else {
      newSearchParams.delete("search");
    }
    
    setSearchParams(newSearchParams);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex gap-2">
      <Controller
        name="filterType"
        control={control}
        render={({ field }) => (
          <Select onValueChange={field.onChange} defaultValue={field.value}>
            <SelectTrigger className="w-24">
              <SelectValue placeholder="Select filter" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">All</SelectItem>
              <SelectItem value="name">Name</SelectItem>
              <SelectItem value="email">Email</SelectItem>
              <SelectItem value="phone">Phone</SelectItem>
            </SelectContent>
          </Select>
        )}
      />
      <Controller
        name="search"
        control={control}
        render={({ field }) => (
          <Input {...field} placeholder={placeholder} className="flex-1" />
        )}
      />
      <Button type="submit">Search</Button>
    </form>
  );
};

export default SearchBar;