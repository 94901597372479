import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Ticket } from "lucide-react";
import { Card, CardContent } from "@/components/ui/card";
import ThreeDotsLoader from "@/components/common/three-dots-loader";
import CustomPaginationComponent from "@/components/common/custom-pagination-component";
import useProfessionals from "@/features/professionals/hooks/use-professionals";
import { ProfessionalCard } from "@/features/professionals/ui/professional-card";
import VaulDrawer from "@/components/ui/drawer";
import AddEditProfessionalsForm from "@/features/professionals/ui/professional-form";
import ProfessionalFilter from "@/features/professionals/ui/filter-professionals";

// Define the filter interface
interface ProfessionalFilters {
  search: string;
  status: string;
  job_commitment: string;
  qualification: string[];
}

export default function Professionals() {
  const [searchParams] = useSearchParams();
  const [showProfessionalFormDrawer, setShowProfessionalFormDrawer] =
    useState(false);
  const [selectedProfessional, setSelectedProfessional] = useState<any>(null);
  const initialPage = parseInt(searchParams.get("page") || "0", 10);
  const [_page, setCurrentPage] = useState(initialPage);
  const navigate = useNavigate();

  // Get filter values from URL params
  const search = searchParams.get("search") || "";
  const status = searchParams.get("status") || undefined;
  const job_commitment = searchParams.get("job_commitment") || undefined;
  const qualification =
    searchParams.get("qualification")?.split(",") || undefined;

  // Fetch professionals with filters
  const { data, error, isFetching, isLoading, isSuccess } = useProfessionals(
    _page,
    search,
    status,
    job_commitment,
    qualification
  );

  if (error) {
    return <></>;
  }

  if (isFetching || isLoading || !data) {
    return <ThreeDotsLoader />;
  }

  const { professionals, lastPage: lastSkip, currentPage } = data;
  const lastPage = lastSkip + 1;

  const handlePageChange = (page: number) => {
    if (page >= 0 && page < lastPage) {
      const newParams = new URLSearchParams(searchParams);
      newParams.set("page", page.toString());
      navigate(`?${newParams.toString()}`, { replace: true });
      setCurrentPage(page);
    }
  };

  const handleApplyFilters = (filters: ProfessionalFilters) => {
    // Filters will be handled by the ProfessionalFilter component through URL params
    // The navigation is handled there, we just need to reset the current page state
    setCurrentPage(0);
  };

  return (
    <div className="flex-1 overflow-auto">
      <div className="h-full p-8">
        <div className="space-y-6">
          {/* Add the filter component */}
          <ProfessionalFilter onApplyFilters={handleApplyFilters} />

          {isSuccess && professionals.length > 0 ? (
            <ProfessionalCard
              professionals={professionals}
              setShowProfessionalDrawer={setShowProfessionalFormDrawer}
              setSelectedProfessional={setSelectedProfessional}
            />
          ) : (
            <Card>
              <CardContent className="flex flex-col items-center justify-center py-8">
                <Ticket className="h-8 w-8 text-muted-foreground" />
                <p className="mt-2 text-center text-sm text-muted-foreground">
                  No professionals found matching your criteria
                </p>
              </CardContent>
            </Card>
          )}

          {professionals?.length > 0 && (
            <CustomPaginationComponent
              currentPage={currentPage}
              lastPage={lastPage}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      </div>

      <VaulDrawer
        drawerDirection="right"
        isOpen={showProfessionalFormDrawer}
        onOpenChange={setShowProfessionalFormDrawer}
        triggerChildren={null}
        contentChildren={
          <AddEditProfessionalsForm
            editMode={!!selectedProfessional}
            onClose={() => setShowProfessionalFormDrawer(false)}
            initialData={selectedProfessional}
          />
        }
      />
    </div>
  );
}
